import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import {
  take,
  map,
  tap,
  distinctUntilChanged,
  shareReplay,
  delay,
  withLatestFrom,
  filter,
  bufferCount,
  debounceTime,
} from 'rxjs/operators';
import { __asyncValues } from 'tslib';
import { TranslocoService } from '@ngneat/transloco';
import { DomSanitizer } from '@angular/platform-browser';
import { DataStoreFacade } from '../../shared/facades';
import { AllocationDataStoreFacade } from '../../shared/facades/allocation-data-store.facade';
import { Group, Tag, UpdateAllocationRuleDto, UpdateAllocationRulesDto } from '../../../core/services/api-clients.generated';
import { Guid } from 'guid-typescript';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AllocationBulkFacade implements OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private readonly defaultState: AllocationBulkState = {
    loading: false,
    allowOverride: false,
    selectedGroups: null,
    selectedTags: null,
    selectedBuildingID: null,
    selectedBuildingFloorZones: null,
    showChartOnBulk: false,
  };
  private readonly stateSubject = new BehaviorSubject<AllocationBulkState>({ ...this.defaultState });
  readonly state$ = this.stateSubject.asObservable();
  readonly loading$ = this.state$.pipe(map((m) => m.loading));
  readonly allowOverride$ = this.state$.pipe(map((m) => m.allowOverride));
  readonly selectedGroups$ = this.state$.pipe(map((m) => m.selectedGroups));
  readonly selectedTags$ = this.state$.pipe(map((m) => m.selectedTags));
  readonly selectedBuildingID$ = this.state$.pipe(map((m) => m.selectedBuildingID)).pipe(distinctUntilChanged());
  readonly selectedBuildingFloorZones$ = this.state$.pipe(map((m) => m.selectedBuildingFloorZones)).pipe(distinctUntilChanged());
  readonly showChartOnBulk$ = this.state$.pipe(map((m) => m.showChartOnBulk));

  public readonly groups$ = combineLatest([this.dataStoreFacade.groups$, this.selectedGroups$]).pipe(
    map(([groups, selectedGroups]) => {
      return groups
        .sort((a, b) => a.groupName.localeCompare(b.groupName))
        .map((group) => {
          return {
            ...group,
            isSelected: !!selectedGroups?.find((f) => f.groupID === group.groupID),
          };
        });
    })
  );

  public readonly tagGroups$ = combineLatest([this.dataStoreFacade.tagGroups$, this.selectedTags$]).pipe(
    withLatestFrom(this.dataStoreFacade.tags$),
    map(([[tagGroups, selectedTags], tags]) => {
      return tagGroups
        .sort((a, b) => a.tagGroupName.localeCompare(b.tagGroupName))
        .map((tagGroup) => {
          return {
            ...tagGroup,
            tags: tags
              .map((tag) => {
                return {
                  ...tag,
                  isSelected: !!selectedTags?.find((f) => f.tagID === tag.tagID),
                };
              })
              .filter((f) => f.tagGroupID === tagGroup.tagGroupID)
              .sort((a, b) => a.displaySequence - b.displaySequence),
          };
        });
    })
  );

  public readonly buildings$ = combineLatest([
    this.allocationDataStoreFacade.allocation$,
    this.allocationDataStoreFacade.buildingFloorZones$,
    this.allocationDataStoreFacade.buildingFloors$,
    this.allocationDataStoreFacade.buildingZones$,
    this.selectedBuildingFloorZones$,
  ]).pipe(
    map(([allocation, buildingFloorZones, buildingFloors, buildingZones, selectedBuildingFloorZones]) => {
      return allocation?.buildings.map((building) => {
        const floorZones = buildingFloors
          .filter((f) => f.buildingID === building.buildingID)
          .map((floor) => {
            const floorFloorZone = buildingFloorZones.find((f) => f.buildingFloorID === floor.buildingFloorID && f.buildingZoneID === null);

            const zones = buildingZones
              .filter((f) => f.buildingID === building.buildingID)
              .map((buildingZone) => {
                const zonefloorZone = buildingFloorZones.find(
                  (f) => f.buildingFloorID === floor.buildingFloorID && f.buildingZoneID === buildingZone.buildingZoneID
                );
                return {
                  ...buildingZone,
                  ...zonefloorZone,
                  isVisible: !!zonefloorZone,
                  isSelected: !!selectedBuildingFloorZones?.find((f) => f.buildingFloorZoneID === zonefloorZone?.buildingFloorZoneID),
                };
              });
            return {
              ...floor,
              buildingFloorZoneID: floorFloorZone?.buildingFloorZoneID,
              // availableArea: floorFloorZone?.availableArea,
              zones,
              hasZones: !!zones.filter((f) => f.isVisible).length,
              isSelected: zones.filter((f) => f.isVisible).length
                ? false
                : !!selectedBuildingFloorZones?.find((f) => f.buildingFloorZoneID === floorFloorZone?.buildingFloorZoneID),
            };
          })
          .sort((a, b) => b.displaySequence - a.displaySequence);
        return {
          building,
          floorZones,
        };
      });
    })
  );

  public readonly selectedBuilding$ = combineLatest([this.buildings$, this.selectedBuildingID$]).pipe(
    map(([buildings, selectedBuildingID]) => {
      const selectedBuilding = buildings.find((f) => f.building.buildingID === selectedBuildingID);
      if (!selectedBuilding) {
        const tmpSelectedBuildingID = buildings[0].building.buildingID;
        this.setSelectedBuildingID(tmpSelectedBuildingID);
        return buildings.find((f) => f.building.buildingID === tmpSelectedBuildingID);
      } else {
        return buildings.find((f) => f.building.buildingID === selectedBuildingID);
      }
    })
  );

  private readonly generatorOutputsFilters$ = combineLatest([this.selectedGroups$, this.selectedTags$, this.allowOverride$]).pipe(
    map(([selectedGroups, selectedTags, allowOverride]) => {
      const groups = selectedGroups?.length ? new Set<string>(selectedGroups?.map((g) => g.groupID)) : null;
      const tags = selectedTags?.length ? new Set<string>(selectedTags?.map((g) => g.tagID)) : null;
      return {
        groups,
        tags,
        allowOverride,
      };
    })
  );

  private selectedFloorZones$ = combineLatest([
    this.selectedBuildingFloorZones$,
    this.allocationDataStoreFacade.buildingFloorZones$,
    this.allocationDataStoreFacade.buildingFloors$,
    this.allocationDataStoreFacade.buildingZones$,
  ]).pipe(
    map(([selectedBuildingFloorZones, buildingFloorZones, buildingFloors, buildingZones]) => {
      if (selectedBuildingFloorZones?.length) {
        return selectedBuildingFloorZones
          .map((i) => {
            const buildingFloorZone = buildingFloorZones.find((f) => f.buildingFloorZoneID === i.buildingFloorZoneID);
            if (buildingFloorZone) {
              const floor = buildingFloors.find((f) => f.buildingFloorID === buildingFloorZone.buildingFloorID);
              const zone =
                buildingFloorZone.buildingZoneID && buildingZones.find((f) => f.buildingZoneID === buildingFloorZone.buildingZoneID);
              return {
                ...buildingFloorZone,
                floor,
                zone,
                displaySequence: 0,
              };
            }
            return null;
          })
          .sort(
            (a, b) =>
              b.availableArea - a.availableArea ||
              a.floor.displaySequence - b.floor.displaySequence ||
              (a.zone?.displaySequence || 0) - (b.zone?.displaySequence || 0)
          )
          .map((m, idx) => {
            m.displaySequence = idx;
            return m;
          });
      } else {
        return [];
      }
    })
  );

  public readonly generatorOutputDetails$ = combineLatest([
    this.dataStoreFacade.generatorOutputs$,
    this.allocationDataStoreFacade.allocationRules$,
    this.dataStoreFacade.spaceTypesDict$,
    this.dataStoreFacade.generatorsDict$,
  ]).pipe(
    map(([generatorOutputs, allocationRules, spaceTypesDict, generatorsDict]) => {
      return generatorOutputs
        .filter((f) => ((f.plannedNoOfSpaceTypes ?? f.generatedNoOfSpaceTypes) || 0) > 0)
        .map((generatorOutput) => {
          const generator = generatorsDict[generatorOutput.generatorID];
          const spaceType = spaceTypesDict[generator.spaceTypeID];
          return {
            generatorOutputID: generatorOutput.generatorOutputID,
            spaceTypeID: spaceType.spaceTypeID,

            generatorOutput,
            generator,
            spaceType,
            allocationRules: allocationRules.filter(
              (f) => f.generatorID === generatorOutput.generatorID && (!f.groupID || f.groupID === generatorOutput.groupID)
            ),
          };
        });
    })
  );

  public readonly selectedGeneratorOutputDetails$ = combineLatest([this.generatorOutputDetails$, this.generatorOutputsFilters$]).pipe(
    map(([generatorOutputDetails, filters]) => {
      const generatorOutputsDetails = generatorOutputDetails.filter(
        (f) =>
          filters &&
          (!filters.groups || filters.groups.has(f.generatorOutput.groupID)) &&
          (!filters.tags || !!f.generator.tags?.find((f1) => filters.tags.has(f1))) &&
          (filters.allowOverride || !!!f.allocationRules?.length)
      );
      return generatorOutputsDetails;
    })
  );

  public readonly selectedSpaceTypeSummary$ = this.selectedGeneratorOutputDetails$.pipe(
    withLatestFrom(this.generatorOutputsFilters$),
    map(([selectedGeneratorOutputDetails, generatorOutputsFilters]) => {
      if (generatorOutputsFilters?.groups?.size || generatorOutputsFilters?.tags?.size) {
        let totalArea = 0;
        const grouped = selectedGeneratorOutputDetails.reduce((v, i) => {
          if (!v[i.spaceTypeID]) {
            v[i.spaceTypeID] = 0;
          }
          const value = (i.generatorOutput.plannedNoOfSpaceTypes ?? i.generatorOutput.generatedNoOfSpaceTypes) * i.spaceType.area;
          v[i.spaceTypeID] += value;
          totalArea += value;
          return v;
        }, {});
        totalArea = Math.round(totalArea);
        return {
          count: Object.keys(grouped)?.length || 0,
          totalArea,
        };
      } else {
        return null;
      }
    })
  );

  public selectedFloorZoneSummary$ = this.selectedFloorZones$.pipe(
    map((selectedFloorZones) => {
      if (selectedFloorZones?.length) {
        return {
          count: selectedFloorZones.length,
          totalArea: selectedFloorZones.reduce((v, i) => {
            return (v += i.availableArea);
          }, 0),
        };
      } else {
        return null;
      }
    })
  );

  constructor(
    private dataStoreFacade: DataStoreFacade,
    private allocationDataStoreFacade: AllocationDataStoreFacade,
    private translocoService: TranslocoService,
    private toastr: ToastrService,
    private sanitized: DomSanitizer
  ) {
    // this.subscriptions.add(
    //   allocationDataStoreFacade.allocation$
    //     .pipe(
    //       bufferCount(2, 1),
    //       filter(([a, b]) => b?.allocationID !== a?.allocationID),
    //       map(([a, b]) => b)
    //     )
    //     .subscribe(() => {
    //       this.resetToDefault();
    //     })
    // );
  }

  toggleOverride() {
    const allowOverride = !this.stateSubject.value.allowOverride;
    this.stateSubject.next({ ...this.stateSubject.value, allowOverride });
  }

  toggleChartBulk() {
    const showChartOnBulk = !this.stateSubject.value.showChartOnBulk;
    this.stateSubject.next({ ...this.stateSubject.value, showChartOnBulk });
  }

  setSelectedGroups(selectedGroups: any[]) {
    const state = this.stateSubject.value;
    const newState = { ...state, selectedGroups };
    this.stateSubject.next(newState);
  }

  setSelectedTags(selectedTags: any[]) {
    const state = this.stateSubject.value;
    const newState = { ...state, selectedTags };
    this.stateSubject.next(newState);
  }

  setSelectedBuildingID(selectedBuildingID: string) {
    const state = this.stateSubject.value;
    const newState = { ...state, selectedBuildingID };
    this.stateSubject.next(newState);
  }

  setSelectedBuildingFloorZones(selectedBuildingFloorZones: any[]) {
    const state = this.stateSubject.value;
    const newState = { ...state, selectedBuildingFloorZones };
    this.stateSubject.next(newState);
  }

  async allocate() {
    combineLatest([this.selectedGeneratorOutputDetails$, this.selectedFloorZones$, this.allocationDataStoreFacade.allocation$])
      .pipe(take(1))
      .subscribe(async ([selectedGeneratorOutputDetails, selectedFloorZones, allocation]) => {
        const updateAllocationRules = [];

        selectedGeneratorOutputDetails.forEach((generatorOutput) => {
          const overrideAllocationRules =
            generatorOutput.allocationRules?.map((m) => ({
              allocationRuleID: m.allocationRuleID,
              allocationID: m.allocationID,
              spaceTypeID: m.spaceTypeID,
              generatorID: m.generatorID,
              groupID: m.groupID,
              buildingFloorZoneID: m.buildingFloorZoneID,
              allocatedQty: null,
              overrideQty: null,
            })) || [];
          const allocationRules = selectedFloorZones
            // .sort((a, b) => a.displaySequence - b.displaySequence) // ??
            .map((m) => {
              const allocationRuleID =
                overrideAllocationRules.find(
                  (f) =>
                    f.buildingFloorZoneID === m.buildingFloorZoneID &&
                    f.spaceTypeID === generatorOutput.spaceTypeID &&
                    (!f.groupID || f.groupID === generatorOutput.generatorOutput.groupID)
                )?.allocationRuleID || Guid.raw();
              return {
                allocationRuleID,
                allocationID: allocation.allocationID,
                spaceTypeID: generatorOutput.spaceTypeID,
                generatorID: generatorOutput.generatorOutput.generatorID,
                groupID: generatorOutput.generatorOutput.groupID,
                buildingFloorZoneID: m.buildingFloorZoneID,
                allocatedQty: 0,
                overrideQty: null,
              };
            });

          let remainder =
            (generatorOutput.generatorOutput.plannedNoOfSpaceTypes ?? generatorOutput.generatorOutput.generatedNoOfSpaceTypes) || 0;
          let index = 0;
          while (remainder > 0) {
            allocationRules[index].allocatedQty += 1;
            remainder--;
            index++;
            if (index >= allocationRules.length) {
              index = 0;
            }
          }

          overrideAllocationRules.forEach((ar) => {
            const idx = allocationRules.findIndex((f) => f.allocationRuleID === ar.allocationRuleID);
            if (idx === -1) {
              allocationRules.push(ar);
            }
          });

          updateAllocationRules.push(...allocationRules);
        });

        const result = await this.allocationDataStoreFacade.updateAllocationRulesBulk(updateAllocationRules);
        this.allocationDataStoreFacade.updateAllocationRulesState(result);
        this.resetSelection();
        this.successToast();
      });
  }

  private successToast() {
    this.toastr.success(
      this.translocoService.translate('root.editor.toast.success.message'),
      this.translocoService.translate('root.editor.toast.success.title')
    );
  }

  get selectedGroups() {
    return this.stateSubject.value.selectedGroups || [];
  }

  get selectedTags() {
    return this.stateSubject.value.selectedTags || [];
  }

  get selectedBuildingFloorZones() {
    return this.stateSubject.value.selectedBuildingFloorZones || [];
  }

  get selectedBuildingID() {
    return this.stateSubject.value.selectedBuildingID || null;
  }

  resetToDefault() {
    this.stateSubject.next({ ...this.defaultState });
  }

  resetSelection() {
    this.stateSubject.next({
      ...this.stateSubject.value,
      selectedGroups: [],
      selectedTags: [],
      selectedBuildingFloorZones: [],
      allowOverride: false,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export interface AllocationBulkState {
  loading: boolean;
  allowOverride: boolean;
  selectedGroups: Group[];
  selectedTags: Tag[];
  selectedBuildingID: string;
  selectedBuildingFloorZones: any[];
  showChartOnBulk: boolean;
}
